import { tokens } from "@bees-grow-shared/theme";
import { SearchField } from "@hexa-ui/components";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 2.5rem;
  width: 28.5rem;
  gap: ${tokens.measure.space["spacing-4"]};
  transform: translateX(-1.75rem);
`;

export const GoBackButtonContainer = styled.div`
  width: 2.5rem;
  height: 2.5rem;
`;

export const Search = styled(SearchField.Root)`
  font-size: ${tokens.measure["font-size"]["fontsize-3"]} !important;
  font-weight: ${tokens.fonts["font-weight"].medium};
  line-height: ${tokens.measure["line-height"]["lineheight-6"]};
  max-height: ${tokens.measure["line-height"]["lineheight-7"]};
`;
