import { useNavigate } from "react-router-dom";

import { cartSharedService, useSharedUnit } from "@bees-grow-shared/services";

import { CHECKOUT_PATH } from "@/config/constants";

interface UseCheckoutButtonProps {
  cartQuantity: number;
  showCartQuantity: boolean;
  handleGoToCheckout: () => void;
}

export function useCheckoutButton(): UseCheckoutButtonProps {
  const navigate = useNavigate();
  const cartService = cartSharedService();
  const { cartItems } = useSharedUnit(cartService);

  const handleGoToCheckout = () => {
    navigate(CHECKOUT_PATH);
  };
  const showCartQuantity =
    !!cartItems?.totalQuantity && cartItems?.totalQuantity > 0;

  return {
    cartQuantity: cartItems?.totalQuantity,
    showCartQuantity,
    handleGoToCheckout,
  };
}
