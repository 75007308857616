import ProductSelection from "@/components/productSelection/ProductSelection";
import TasksCarousel from "@/components/tasksCarousel/TasksCarousel";

import * as Styled from "./MissionsPage.styles";

export default function MissionsPage() {
  return (
    <Styled.Container>
      <TasksCarousel />
      <ProductSelection />
    </Styled.Container>
  );
}
