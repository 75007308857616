import { useIntl } from "react-intl";

import { IconButton } from "@hexa-ui/components";
import { ArrowLeft } from "@hexa-ui/icons";
import { AnimatePresence, motion } from "framer-motion";

import { useProductsSearch } from "./hooks/useProductsSearch";
import * as Styled from "./ProductsSearch.styles";

export default function ProductsSearch() {
  const { formatMessage } = useIntl();
  const {
    handleChange,
    handleSearch,
    handleClear,
    handleGoBack,
    searchInput,
    showGoBackButton,
  } = useProductsSearch();

  return (
    <Styled.Container data-testid="products-search">
      <Styled.GoBackButtonContainer data-testid="products-search-go-back-button-container">
        <AnimatePresence>
          {showGoBackButton && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5, ease: "easeOut" }}
            >
              <IconButton
                variant="tertiary"
                icon={ArrowLeft}
                onClick={handleGoBack}
                data-testid="products-search-go-back-button"
              />
            </motion.div>
          )}
        </AnimatePresence>
      </Styled.GoBackButtonContainer>
      <Styled.Search
        value={searchInput}
        onChange={handleChange}
        onSearch={handleSearch}
        onClear={handleClear}
        width="25rem"
        placeholder={formatMessage({
          id: "ProductSelection.Search.PLACEHOLDER",
        })}
        data-testid="products-search-input-search"
      />
    </Styled.Container>
  );
}
