import ResponsiveGridWrapper from "./components/ResponsiveGridWrapper/ResponsiveGridWrapper";
import { useProductSelection } from "./hooks/useProductSelection";
import * as Styled from "./ProductSelection.styles";

export default function ProductSelection() {
  const { items, isLoading } = useProductSelection();

  if (isLoading) {
    return <LoadingBehavior />;
  }

  return (
    <Styled.Container data-testid="product-selection">
      <Styled.ProductBoardTitle
        size="H3"
        data-testid="product-selection-header"
      >
        Products
      </Styled.ProductBoardTitle>
      <Styled.ProductBoard data-testid="product-selection-board">
        <ResponsiveGridWrapper>{items}</ResponsiveGridWrapper>
      </Styled.ProductBoard>
    </Styled.Container>
  );
}

function LoadingBehavior() {
  return (
    <Styled.Container data-testid="product-selection-loading">
      <Styled.ProductBoardTitleLoad data-testid="product-selection-header-loading" />
      <Styled.ProductBoard data-testid="product-selection-board-loading">
        <ResponsiveGridWrapper isLoading />
      </Styled.ProductBoard>
    </Styled.Container>
  );
}
