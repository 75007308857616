import { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";

import { OptionsProps } from "@bees-grow-shared/components";
import {
  accountSharedService,
  productCategorySharedService,
  ProductsMode,
  productsSharedService,
  useDefaultAnalyticsData,
  useSharedUnit,
} from "@bees-grow-shared/services";

import analytics, { PromotionType } from "../../../config/typewriter";

export interface IUseProductSelectionProps {
  category: string;
}
export function useProductSelection() {
  const { formatMessage } = useIntl();
  const defaultData = useDefaultAnalyticsData();
  const productCategoryService = productCategorySharedService();
  const accountService = accountSharedService();
  const productService = productsSharedService();

  const {
    mode,
    products,
    categoryId,
    steppedDiscountDrawer: { showDrawer },
    pageSearchStatus: { done: isPageSearchStatusDone },
    initialSearchStatus: {
      done: isInitialSearchStatusDone,
      isLoading: isInitialSearchStatusLoading,
    },
  } = useSharedUnit(productService);
  const { accountId, vendorId } = useSharedUnit(accountService);
  const {
    categories,
    done: categoriesDone,
    isLoading: categoriesLoading,
    error: categoriesError,
  } = useSharedUnit(productCategoryService);

  const showSearchDetails = useMemo(
    () =>
      !isInitialSearchStatusLoading &&
      mode === ProductsMode.SEARCH &&
      products?.length > 0,
    [isInitialSearchStatusLoading, mode, products],
  );

  const showSearchFilter = useMemo(
    () => !isInitialSearchStatusLoading && mode === ProductsMode.SEARCH,
    [isInitialSearchStatusLoading, mode],
  );

  const showSelectCategory = useMemo(
    () => !isInitialSearchStatusLoading && mode !== ProductsMode.SEARCH,
    [isInitialSearchStatusLoading, mode],
  );

  const trackProductsLoaded = () => {
    analytics.productsLoaded({
      products: products.map((item) => {
        const product = item.variants[0];
        return {
          product_name: product.name,
          product_task: false,
          sku: product.sku,
          vendor_item_id: product.vendorId,
          platform_item_id: product.platformId,
          category: selectedOption?.label,
          promotion_type: product.price?.promotion
            ? (Object.keys(product.price.promotion)[0] as PromotionType)
            : null,
          suggested_quantity: product.quantityInfo?.suggested,
          product_quantity: product.quantityInfo?.stock,
          variant_type: product.variantDescription,
        };
      }),
      ...defaultData,
      source: selectedOption?.label,
      screen_name: "MISSIONS",
    });
  };

  useEffect(() => {
    if (isPageSearchStatusDone) {
      trackProductsLoaded();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageSearchStatusDone]);

  useEffect(() => {
    if (isInitialSearchStatusDone) {
      trackProductsLoaded();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialSearchStatusDone]);

  const callGetCategories = useCallback(() => {
    if ((!categoriesLoading && !categoriesDone) || categoriesError) {
      productCategoryService.getCategories({
        accountId,
        vendorId,
      });
    }
  }, [
    accountId,
    vendorId,
    categoriesDone,
    categoriesLoading,
    categoriesError,
    productCategoryService,
  ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => callGetCategories(), []);

  const handleSelect = useCallback(
    (item) => {
      const formattedMode = item.value;
      const categoryId =
        formattedMode === ProductsMode.CATEGORY ? item.key : undefined;

      productService.changeMode({
        mode: item.value,
        categoryId,
      });
    },
    [productService],
  );

  const flatMapOptions = useCallback((options) => {
    return options.reduce((result, option) => {
      result.push(option);
      if (option.options) {
        result = result.concat(flatMapOptions(option.options));
      }
      return result;
    }, []);
  }, []);

  const formatCategoryAsOption = useCallback(
    (category) => {
      const isFixedCategory = Object.values(ProductsMode).includes(category.id);

      return {
        key: category.id,
        label: isFixedCategory
          ? formatMessage({ id: "ProductSelection.Modes." + category.name })
          : category.name,
        value: isFixedCategory ? category.id : ProductsMode.CATEGORY,
        options: category.categories?.map(formatCategoryAsOption),
        hasBorder: category.id === ProductsMode.RECOMMENDED,
      };
    },
    [formatMessage],
  );

  const selectOptions: OptionsProps[] = useMemo(() => {
    return categories.map(formatCategoryAsOption);
  }, [categories, formatCategoryAsOption]);

  const selectedOption = useMemo(() => {
    if (categoryId) {
      return flatMapOptions(selectOptions).find(
        (option) => option.key == categoryId,
      );
    }
    return selectOptions.find((option) => option.value === mode);
  }, [mode, categoryId, selectOptions, flatMapOptions]);

  const callGetProducts = useCallback(() => {
    productService.getProducts();
  }, [productService]);

  useEffect(() => {
    if (mode == undefined) {
      productService.changeMode({
        mode: ProductsMode.SUGGESTED,
      });
    }
  }, [mode, productService]);

  useEffect(() => {
    if (mode && !isInitialSearchStatusDone) {
      callGetProducts();
    }
  }, [mode, isInitialSearchStatusDone, callGetProducts]);

  return {
    handleSelect,
    callGetProducts,
    selectOptions,
    selectedOption,
    showSearchDetails,
    showSearchFilter,
    showSelectCategory,
    categoriesLoading,
    categoriesError,
    retryCategories: callGetCategories,
    showDrawer,
  };
}
