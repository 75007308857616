import { useEffect, useState } from "react";

export function useProductSelection() {
  const items = [];

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2500);

    return () => clearTimeout(timer);
  }, [isLoading]);

  return { items, isLoading };
}
