import { tokens } from "@bees-grow-shared/theme";
import { Button, Paragraph, SkeletonLoader } from "@hexa-ui/components";
import styled from "styled-components";

export const Container = styled.div`
  gap: ${tokens.measure.space["spacing-4"]};
  display: flex;
  flex-direction: column;
`;

export const ItemsContainer = styled.div`
  border-bottom: 1px solid ${tokens.color.foundation.border.secondary};
  & > *:not(:last-child) {
    border-bottom-width: 0;
  }
`;

export const Script = styled(Paragraph)`
  font-family: ${tokens.fonts["font-family"]["Work Sans"]};
  font-size: ${tokens.measure["font-size"]["fontsize-3"]};
  font-weight: ${tokens.fonts["font-weight"].normal};
  line-height: ${tokens.measure["line-height"]["lineheight-6"]};
  text-align: left;
`;

export const ContainerLoading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
`;

export const LineLoading = styled(SkeletonLoader)`
  border-radius: ${tokens.measure["border-radius"]["borderradius-1"]};
  height: 14px;
  width: 25rem;
`;

export const HalfLineLoading = styled(LineLoading)`
  width: 11.1875rem;
`;

export const ContainerError = styled.div`
  gap: ${tokens.measure.space["spacing-6"]};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerMessageIconError = styled.div`
  gap: ${tokens.measure.space["spacing-4"]};
  width: 11.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ParagraphError = styled(Paragraph)`
  font-size: ${tokens.measure["font-size"]["fontsize-2"]};
  line-height: ${tokens.measure["line-height"]["lineheight-5"]};
  text-align: center;
`;

export const ReloadError = styled(Button)`
  width: fit-content;
`;
