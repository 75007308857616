import { useCallback, useEffect, useState } from "react";

interface GridSettings {
  columns: number;
  rows: number;
}

export default function useResponsiveGridWrapper() {
  const getGridSettings = useCallback((): GridSettings => {
    const width = window.innerWidth;
    if (width < 1024) return { columns: 2, rows: 5 };
    if (width < 1440) return { columns: 3, rows: 3 };
    if (width < 1920) return { columns: 4, rows: 3 };
    return { columns: 5, rows: 3 };
  }, []);

  const [gridSettings, setGridSettings] =
    useState<GridSettings>(getGridSettings);

  useEffect(() => {
    const handleResize = () => setGridSettings(getGridSettings());

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [getGridSettings]);

  const getLastInRowIndex = useCallback(
    (index: number, columns: number): boolean => {
      return (index + 1) % columns === 0;
    },
    [],
  );

  const getLastInColumnIndex = useCallback(
    (index: number, columns: number, totalItems: number): boolean => {
      const rows = Math.ceil(totalItems / columns);
      return index >= (rows - 1) * columns;
    },
    [],
  );

  return { gridSettings, getLastInRowIndex, getLastInColumnIndex };
}
