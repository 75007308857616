import { useIntl } from "react-intl";

import { Paragraph } from "@hexa-ui/components";

import { useSearchDetails } from "./hooks/useSearchDetails";
import * as Styled from "./SearchDetails.styles";

export default function SearchDetails() {
  const { formatMessage } = useIntl();
  const { searchInput, quantityProducts } = useSearchDetails();

  return (
    <Styled.Container data-testid="search-details">
      <Paragraph data-testid="search-details-summary-text">
        {formatMessage(
          {
            id: "ProductSelection.Search.Details.SUMMARY_TEXT",
          },
          { quantityProducts },
        )}
        <Styled.Bold>{`"${searchInput}"`}</Styled.Bold>
      </Paragraph>
    </Styled.Container>
  );
}
