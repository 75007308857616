import { useNavigate } from "react-router-dom";

import { CHECKOUT_PATH } from "@/config/constants";

interface UseCheckoutButtonProps {
  handleGoToCheckout: () => void;
}

export function useCheckoutButton(): UseCheckoutButtonProps {
  const navigate = useNavigate();

  const handleGoToCheckout = () => {
    navigate(CHECKOUT_PATH);
  };

  return { handleGoToCheckout };
}
