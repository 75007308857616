import { tokens } from "@bees-grow-shared/theme";
import { SkeletonLoader } from "@hexa-ui/components";
import styled from "styled-components";

export const CategoryLoadContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
`;

export const CategoryLoad = styled(SkeletonLoader)`
  width: 6.5rem;
  height: ${tokens.measure["line-height"]["lineheight-6"]};
`;

export const CategoryErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${tokens.measure.space["spacing-2"]};
  height: 15rem;
  padding: 1rem;
`;
