import { tokens } from "@bees-grow-shared/theme";
import styled from "styled-components";

export const Container = styled.div`
  gap: ${tokens.measure.space["spacing-2"]};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const BadgeCounter = styled.div`
  font-family: ${tokens.fonts["font-family"]["Work Sans"]};
  font-size: ${tokens.measure["font-size"]["fontsize-2"]};
  font-weight: ${tokens.fonts["font-weight"].semibold};
  line-height: ${tokens.measure["line-height"]["lineheight-5"]};
  color: ${tokens.color.foundation.border.on.elevated["high-contrast"]};
  text-align: center;
  background-color: ${tokens.color.foundation.bg.info.default};
  border: ${tokens.measure["border-width"].hairline} solid
    ${tokens.color.foundation.border.on.elevated["high-contrast"]};
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
