import { FormEvent, useCallback, useMemo, useState } from "react";

import {
  ProductsMode,
  productsSharedService,
  useSharedUnit,
} from "@bees-grow-shared/services";

interface UseProductsSearchProps {
  handleChange: (e: FormEvent<HTMLInputElement>) => void;
  handleSearch: () => void;
  handleClear: () => void;
  handleGoBack: () => void;
  searchInput: string;
  showGoBackButton: boolean;
  isLoading: boolean;
}

export function useProductsSearch(): UseProductsSearchProps {
  const productService = productsSharedService();
  const {
    searchInput: searchInputProductStore,
    initialSearchStatus: { isLoading: isLoadingInitialSearch },
  } = useSharedUnit(productService);

  const [searchInput, setSearchInput] = useState<string>(
    searchInputProductStore ? searchInputProductStore : "",
  );

  const hasSearched = useMemo(
    () => searchInputProductStore !== undefined,
    [searchInputProductStore],
  );

  const handleChange = useCallback((e: FormEvent<HTMLInputElement>) => {
    setSearchInput(e.currentTarget.value);
  }, []);

  const handleSearch = useCallback(() => {
    if (searchInput !== "") {
      productService.changeMode({
        mode: ProductsMode.SEARCH,
        searchInput,
      });
    }
  }, [productService, searchInput]);

  const handleClear = useCallback(() => {
    setSearchInput("");
  }, []);

  const handleGoBack = useCallback(() => {
    setSearchInput("");
    if (hasSearched) {
      productService.changeMode({
        mode: null,
      });
    }
  }, [productService, hasSearched]);

  return {
    handleChange,
    handleSearch,
    handleClear,
    handleGoBack,
    searchInput,
    showGoBackButton: hasSearched,
    isLoading: isLoadingInitialSearch,
  };
}
