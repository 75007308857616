import { Truck } from "@hexa-ui/icons";

import * as Styled from "./CheckoutButton.styles";
import { useCheckoutButton } from "./hooks/useCheckoutButton";

export default function CheckoutButton() {
  const { handleGoToCheckout } = useCheckoutButton();

  return (
    <div data-testid="checkout-button">
      <Styled.IconButton
        icon={Truck}
        variant="tertiary"
        onClick={handleGoToCheckout}
      />
    </div>
  );
}
