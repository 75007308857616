import { useCallback, useEffect, useRef } from "react";

export interface UseInfiniteScrollProps {
  loadMore: () => void;
  hasMore: boolean;
  loading: boolean;
  error: boolean;
}

export const useInfiniteScroll = ({
  loadMore,
  hasMore,
  loading,
  error,
}: UseInfiniteScrollProps) => {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const loaderRef = useRef<HTMLDivElement>(null);

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target.isIntersecting && hasMore && !loading && !error) {
        loadMore();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasMore, loading, error, loadMore, loaderRef.current],
  );

  useEffect(() => {
    observerRef.current = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "500px",
      threshold: 0.7,
    });

    const currentObserver = observerRef.current;
    const currentLoader = loaderRef.current;
    if (currentLoader) {
      currentObserver.observe(currentLoader);
    }

    return () => {
      if (currentLoader) {
        currentObserver.unobserve(currentLoader);
      }
    };
  }, [handleObserver]);

  return { loaderRef };
};
