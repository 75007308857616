import { useIntl } from "react-intl";

import { ItemCard } from "@bees-grow-shared/business-components";
import { SelectedOption } from "@bees-grow-shared/components";
import {
  Combo as ComboDomain,
  isProduct,
  Product as ProductDomain,
} from "@bees-grow-shared/services";
import { Button } from "@hexa-ui/components";

import EmptyStateMediumIcon from "@/assets/EmptyStateMediumIcon";
import EmptyProductSVG from "@/assets/svg/EmptyProducts.svg";
import NoSearchResultsSVG from "@/assets/svg/NoSearchResults.svg";

import PaginationSection from "../paginationSection/PaginationSection";
import ResponsiveGridWrapper from "../ResponsiveGridWrapper/ResponsiveGridWrapper";
import { useProductsGrid } from "./hooks/useProductsGrid";
import * as Styled from "./ProductsGrid.styles";

interface ProductsGridProps {
  selectedOption: SelectedOption;
  onReloadError: () => void;
}

export default function ProductsGrid({
  selectedOption,
  onReloadError,
}: ProductsGridProps) {
  const {
    isInitialSearchStatusLoading,
    isInitialSearchStatusError,
    hasEmptyProducts,
    products,
    searchInput,
  } = useProductsGrid();

  if (isInitialSearchStatusLoading) {
    return <LoadingBehavior />;
  }

  if (isInitialSearchStatusError) {
    return <ErrorBehavior onReload={onReloadError} />;
  }

  if (hasEmptyProducts) {
    return (
      <EmptyBehavior
        selectedOption={selectedOption}
        searchInput={searchInput}
      />
    );
  }

  return (
    <>
      <Styled.Container data-testid="products-grid">
        <ResponsiveGridWrapper>
          {products.map((item: ProductDomain | ComboDomain) => {
            if (isProduct(item)) {
              return (
                <ItemCard.Product
                  item={item}
                  key={item.variants[0].platformId}
                  analyticsData={{
                    category: selectedOption?.label,
                    isProductTask: false,
                    screenName: "MISSIONS",
                  }}
                />
              );
            }
            return null;
          })}
        </ResponsiveGridWrapper>
      </Styled.Container>

      <PaginationSection />
    </>
  );
}

function LoadingBehavior() {
  return (
    <div data-testid="products-grid-loading-behavior">
      <ResponsiveGridWrapper isLoading />
    </div>
  );
}

interface EmptyBehaviorProps {
  selectedOption: SelectedOption;
  searchInput?: string;
}

function EmptyBehavior({
  selectedOption,
  searchInput,
}: Readonly<EmptyBehaviorProps>) {
  const { formatMessage } = useIntl();
  const hasSearched = searchInput !== undefined;

  const icon = hasSearched ? NoSearchResultsSVG : EmptyProductSVG;
  const description = hasSearched
    ? formatMessage(
        {
          id: "ProductSelection.Empty.EMPTY_PRODUCTS_SEARCH_MESSAGE",
        },
        { searchInput },
      )
    : formatMessage(
        {
          id: "ProductSelection.Empty.EMPTY_PRODUCTS_MESSAGE",
        },
        { param: selectedOption?.label },
      );

  return (
    <Styled.EmptyBehaviorContainer data-testid="products-grid-empty-behavior">
      <Styled.HexaEmptyState
        description={description}
        customIlustration={{
          src: icon,
          alt: description,
        }}
      />
    </Styled.EmptyBehaviorContainer>
  );
}

interface ErrorBehaviorProps {
  onReload: () => void;
}

function ErrorBehavior({ onReload }: Readonly<ErrorBehaviorProps>) {
  const { formatMessage } = useIntl();

  return (
    <Styled.ErrorContainer data-testid="products-grid-error-behavior">
      <EmptyStateMediumIcon />
      <Styled.ErrorParagraph>
        {formatMessage({
          id: "ProductSelection.Error.DESCRIPTION",
        })}
      </Styled.ErrorParagraph>
      <Button variant="secondary" onClick={onReload}>
        {formatMessage({
          id: "ProductSelection.Error.BUTTON",
        })}
      </Button>
    </Styled.ErrorContainer>
  );
}
