import {
  AnalyticsWrapper,
  OptimizelyWrapper,
  RouterWrapper,
  UserConfigWrapper,
} from "@bees-grow-shared/business-components";
import { ThemeProvider } from "@bees-grow-shared/theme";

import { EnvProvider } from "./components/envProvider/EnvProvider";
import { EnvConfig } from "./components/envProvider/EnvProvider.types";
import { IntlProvider } from "./i18n";
import MissionsPage from "./pages/missionsPage/MissionsPage";

export default function App(props: Readonly<EnvConfig>) {
  return (
    <ThemeProvider>
      <UserConfigWrapper>
        <OptimizelyWrapper sdkKey={props.OPTIMIZELY_KEY}>
          <AnalyticsWrapper segmentKey={props.SEGMENT_KEY}>
            <EnvProvider env={props}>
              <IntlProvider>
                <RouterWrapper>
                  <MissionsPage />
                </RouterWrapper>
              </IntlProvider>
            </EnvProvider>
          </AnalyticsWrapper>
        </OptimizelyWrapper>
      </UserConfigWrapper>
    </ThemeProvider>
  );
}
