import { Select } from "@bees-grow-shared/components";

import CategoryStatus from "./components/CategoryStatus/CategoryStatus";
import ProductSelectionHeader from "./components/productSelectionHeader/ProductSelectionHeader";
import ProductsGrid from "./components/productsGrid/ProductsGrid";
import ProductsSearch from "./components/productsSearch/ProductsSearch";
import SearchDetails from "./components/searchDetails/SearchDetails";
import SearchFilter from "./components/searchFilter/SearchFilter";
import SteppedDiscountDrawer from "./components/steppedDiscountDrawer/SteppedDiscountDrawer";
import { useProductSelection } from "./hooks/useProductSelection";
import * as Styled from "./ProductSelection.styles";

export default function ProductSelection() {
  const {
    handleSelect,
    selectOptions,
    callGetProducts,
    selectedOption,
    showSearchDetails,
    showSearchFilter,
    showSelectCategory,
    categoriesLoading,
    categoriesError,
    retryCategories,
    showDrawer,
  } = useProductSelection();

  return (
    <Styled.Container data-testid="product-selection">
      <ProductSelectionHeader />
      <Styled.ProductBoard data-testid="product-selection-board">
        <Styled.SearchContainer>
          <ProductsSearch />
        </Styled.SearchContainer>
        <Styled.Divider />
        <Styled.ContentContainer>
          {showSearchFilter && <SearchFilter />}
          {showSearchDetails && <SearchDetails />}
          {showSelectCategory && (
            <Styled.SelectFilterContainer>
              <Select
                onChange={handleSelect}
                options={selectOptions}
                selectedOption={selectedOption}
                optionsAppendage={
                  <CategoryStatus
                    categoriesLoading={categoriesLoading}
                    categoriesError={categoriesError}
                    retryCategories={retryCategories}
                  />
                }
              />
            </Styled.SelectFilterContainer>
          )}
          <ProductsGrid
            selectedOption={selectedOption}
            onReloadError={callGetProducts}
          />
        </Styled.ContentContainer>
      </Styled.ProductBoard>
      {showDrawer && (
        <SteppedDiscountDrawer data-testid="stepped-discount-drawer" />
      )}
    </Styled.Container>
  );
}
