import {
  productsSharedService,
  useSharedUnit,
} from "@bees-grow-shared/services";

interface UseSearchDetailsProps {
  searchInput: string;
  quantityProducts: number;
}

export function useSearchDetails(): UseSearchDetailsProps {
  const productsService = productsSharedService();
  const { searchInput, products } = useSharedUnit(productsService);

  return {
    searchInput,
    quantityProducts: products?.length ?? 0,
  };
}
