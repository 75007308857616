// import { tokens } from "@bees-grow-shared/theme";
import { Button, Paragraph } from "@hexa-ui/components";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  border-top: 1px solid #1414141f;
  padding: 20px 0 0 0;
`;

export const ErrorContainer = styled(Container)`
  display: block;
`;

export const ErrorText = styled(Paragraph)`
  text-align: center;
`;

export const RetryButton = styled(Button)`
  margin: 0.5rem auto;
`;
