import { useCallback } from "react";

import { missionSharedService } from "@bees-grow-shared/services";

interface UseMarkAsDoneParams {
  id: string;
}

interface UseMarkAsDoneProps {
  handleMarkAsDoneClick: () => void;
  handleMarkAsNotDoneClick: () => void;
}

export function useMarkAsDone({ id }: UseMarkAsDoneParams): UseMarkAsDoneProps {
  const missionService = missionSharedService();

  const handleMarkAsDoneClick = useCallback(() => {
    missionService.setMissionEffectiveness({
      missionId: id,
      effectiveness: true,
    });
  }, [id, missionService]);

  const handleMarkAsNotDoneClick = useCallback(() => {
    missionService.setMissionEffectiveness({
      missionId: id,
      effectiveness: false,
    });
  }, [id, missionService]);

  return {
    handleMarkAsDoneClick,
    handleMarkAsNotDoneClick,
  };
}
