import { lastOrdersSharedService } from "@bees-grow-shared/services";

import { ShowLastOrderButton } from "./ShowLastOrderButton.styles";

export default function LastOrderButton() {
  const lastOrdersService = lastOrdersSharedService();

  const handleGoToFullTable = () => {
    lastOrdersService.setLastOrdersState({
      showLastOrderModal: true,
    });
  };

  return (
    <ShowLastOrderButton variant="secondary" onClick={handleGoToFullTable}>
      Show last order
    </ShowLastOrderButton>
  );
}
