import { tokens } from "@bees-grow-shared/theme";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin-top: 1.6rem;

  tr:nth-child(odd) {
    background-color: ${tokens.color.primitive.solid.neutral["neutral-10"]};
  }
  tr:hover {
    background-color: ${tokens.color.primitive.solid.neutral["neutral-20"]};
  }

  tr {
    border: none !important;
  }

  tbody td:nth-child(2) {
    font-weight: ${tokens.fonts["font-weight"].semibold};
    color: ${tokens.color.primitive.solid.green["green-80"]};
    white-space: nowrap;
  }
`;
