import MessageMap from "../i18n.d";

const ptBR: MessageMap = {
  TasksCarousel: {
    TITLE: "Tarefas de ligação",
    Empty: {
      DESCRIPTION: "Não há tarefas atribuídas a esta POC.",
    },
    Error: {
      DESCRIPTION: "Não é possível carregar as tarefas da chamada.",
      BUTTON: "Tentar novamente",
      GENERIC_ERROR: "Algo deu errado. Tente novamente mais tarde.",
    },
    TaskCard: {
      TOOLTIP_SPONSORED: "Patrocinadas",
      Global: {
        Title: {
          TOPLINE: "Tarefa de topline",
          MARKETPLACE: "Tarefa de marketplace",
        },
        Description: {
          TOPLINE: "Recomendar produtos com base na ordem sugerida.",
          MARKETPLACE: "Incentivar a compra de produtos do marketplace.",
        },
      },
      Local: {
        Description: {
          DEFAULT: "Tarefa personalizada criada pelas equipes de operações.",
        },
      },
    },
    TaskDrawer: {
      Error: {
        RELOAD: "Recarregar",
        GENERIC_ERROR:
          "Não é possível carregar as informações. Verifique sua conexão e tente novamente.",
      },
    },
  },
};

export default ptBR;
