import { Paragraph } from "@hexa-ui/components";

import { useInfiniteScroll } from "./hooks/useInfiniteScroll/useInfiniteScroll";
import { usePaginationSection } from "./hooks/usePaginationSection/usePaginationSection";
import * as Styled from "./PaginationSection.styles";

interface ErrorBehaviorProps {
  onReload: () => void;
  retryButtonText: string;
  errorText: string;
}

export default function PaginationSection() {
  const {
    isPageSearchStatusLoading,
    isPageSearchStatusError,
    isPageSearchStatusDone,
    hasNextPage,
    retryButtonText,
    errorText,
    loadingText,
    emptyStateText,
    callGetNextPage,
  } = usePaginationSection();

  const { loaderRef } = useInfiniteScroll({
    loadMore: callGetNextPage,
    hasMore: hasNextPage,
    loading: isPageSearchStatusLoading,
    error: isPageSearchStatusError,
  });

  if (isPageSearchStatusLoading) {
    return <LoadingBehavior loadingText={loadingText} />;
  }

  if (isPageSearchStatusError) {
    return (
      <ErrorBehavior
        onReload={callGetNextPage}
        retryButtonText={retryButtonText}
        errorText={errorText}
      />
    );
  }

  if (!hasNextPage && isPageSearchStatusDone) {
    return <EmptyBehavior emptyStateText={emptyStateText} />;
  }

  return <div ref={loaderRef} data-testid="infinite-scroll-ref" />;
}

function LoadingBehavior({ loadingText }: { loadingText: string }) {
  return (
    <Styled.Container data-testid="pagination-section-loading-behavior">
      <Paragraph>{loadingText}</Paragraph>
    </Styled.Container>
  );
}

function ErrorBehavior({
  onReload,
  retryButtonText,
  errorText,
}: ErrorBehaviorProps) {
  return (
    <Styled.ErrorContainer data-testid="pagination-section-error-behavior">
      <Styled.ErrorText>{errorText}</Styled.ErrorText>
      <Styled.RetryButton size="small" variant="secondary" onClick={onReload}>
        {retryButtonText}
      </Styled.RetryButton>
    </Styled.ErrorContainer>
  );
}

function EmptyBehavior({ emptyStateText }: { emptyStateText: string }) {
  return (
    <Styled.Container data-testid="pagination-section-empty-behavior">
      <Paragraph>{emptyStateText}</Paragraph>
    </Styled.Container>
  );
}
