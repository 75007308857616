import MessageMap from "../i18n.d";

const enUS: MessageMap = {
  TasksCarousel: {
    TITLE: "Call tasks",
    Empty: {
      DESCRIPTION: "There is no tasks assigned to this POC.",
    },
    Error: {
      DESCRIPTION: "Unable to load call tasks.",
      BUTTON: "Try again",
    },
    TaskCard: {
      TOOLTIP_SPONSORED: "Sponsored",
      Global: {
        Title: {
          TOPLINE: "Topline task",
          MARKETPLACE: "Marketplace task",
        },
        Description: {
          TOPLINE: "Recommend products based on the suggested order.",
          MARKETPLACE: "Encourage the purchase of marketplace products.",
        },
      },
      Local: {
        Description: {
          DEFAULT: "Customized task created by the operations teams.",
        },
      },
    },
    TaskDrawer: {
      MARK_AS_DONE: "Mark as done",
      MARK_AS_NOT_DONE: "Mark as not done",
      Error: {
        RELOAD: "Reload",
        GENERIC_ERROR:
          "Unable to load information. Check your connection and try again.",
      },
    },
  },
  ProductSelection: {
    TITLE: "Products",
  },
};

export default enUS;
