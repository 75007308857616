import { MissionSubtype, MissionType } from "@bees-grow-shared/services";

interface UseDrawerBodyParams {
  type: MissionType;
  subType: MissionSubtype;
}

interface UseDrawerBodyProps {
  showMarkAsDone: boolean;
}

export function useDrawerBody({
  type,
  subType,
}: UseDrawerBodyParams): UseDrawerBodyProps {
  const showMarkAsDone =
    type !== MissionType.GLOBAL && subType === MissionSubtype.simple;

  return { showMarkAsDone };
}
