/* eslint-disable no-console */
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

import {
  Product,
  productsSharedService,
  useCurrencyFormatter,
  useSharedUnit,
} from "@bees-grow-shared/services";

export const useSteppedDiscountDrawer = () => {
  const productService = productsSharedService();
  const currencyFormatter = useCurrencyFormatter;
  const { formatMessage } = useIntl();

  const {
    products: productsFromStore,
    steppedDiscountDrawer: { selectedPlatformId, showDrawer },
  } = useSharedUnit(productService);
  const products = productsFromStore as Product[];

  const selectedProductPrice = useMemo(
    () =>
      products.filter((product: Product) => {
        return product.variants[0].platformId === selectedPlatformId;
      })[0]?.variants?.[0]?.price,
    [products, selectedPlatformId],
  );

  const createTableRows = useCallback(() => {
    const value = selectedProductPrice?.value;

    return selectedProductPrice?.promotion?.steppedDiscounts.map(
      (discountPrice) => {
        const quantity = discountPrice.finalQuantity;
        const discount = (
          ((value - discountPrice.priceWithDiscount) / value) *
          100
        ).toFixed(0);
        const discountMessage = formatMessage(
          {
            id: "ProductSelection.SteppedDiscountDrawer.DISCOUNT_MESSAGE",
          },
          { quantity, discount },
        );
        return {
          discount: discountMessage,
          price: currencyFormatter(discountPrice.priceWithDiscount, {
            minimumFractionDigits: 2,
          }),
        };
      },
    );
  }, [
    selectedProductPrice?.value,
    selectedProductPrice?.promotion?.steppedDiscounts,
    formatMessage,
    currencyFormatter,
  ]);

  const handleShowSteppedDiscountDrawer = useCallback(() => {
    productService.setProductsState({
      steppedDiscountDrawer: { showDrawer: !showDrawer },
    });
  }, [productService, showDrawer]);

  return {
    showDrawer,
    handleShowSteppedDiscountDrawer,
    createTableRows,
  };
};
