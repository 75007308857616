import LoadingCard from "./components/LoadingCard/LoadingCard";
import useResponsiveGridWrapper from "./hooks/useResponsiveGridWrapper";
import * as Styled from "./ResponsiveGridWrapper.styles";

type ReactElementWithKey = React.ReactElement & { key: string | number | null };

interface ResponsiveGridProps {
  children?: ReactElementWithKey[];
  isLoading?: boolean;
}

export default function ResponsiveGridWrapper({
  children,
  isLoading = false,
}: ResponsiveGridProps): JSX.Element {
  const { gridSettings, getLastInRowIndex, getLastInColumnIndex } =
    useResponsiveGridWrapper();

  if (isLoading) {
    return (
      <LoadingBehavior
        numberOfCards={gridSettings.rows * gridSettings.columns}
      />
    );
  }

  return (
    <Styled.GridContainer data-testid="grid-container">
      {children?.map((child, index) => (
        <Styled.GridCard
          key={child.key}
          data-testid={`grid-card-${index}`}
          isLastInRow={getLastInRowIndex(index, gridSettings.columns)}
          isLastInColumn={getLastInColumnIndex(
            index,
            gridSettings.columns,
            children.length,
          )}
        >
          {child}
        </Styled.GridCard>
      ))}
    </Styled.GridContainer>
  );
}

function LoadingBehavior({ numberOfCards }: { numberOfCards: number }) {
  return (
    <Styled.GridContainer data-testid="loading-grid-container">
      {Array(numberOfCards)
        .fill(null)
        .map((_, index) => (
          <LoadingCard
            key={`loading-card-${index}`}
            data-testid={`loading-card-${index}`}
          />
        ))}
    </Styled.GridContainer>
  );
}
