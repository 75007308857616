import { useIntl } from "react-intl";

import { Button } from "@hexa-ui/components";
import { AnimatePresence, motion } from "framer-motion";

import * as Styled from "./CategoryStatus.styles";

interface CategoryStatusProps {
  categoriesLoading: boolean;
  categoriesError: boolean;
  retryCategories: () => void;
}
export default function CategoryStatus({
  categoriesLoading,
  categoriesError,
  retryCategories,
}: CategoryStatusProps) {
  const { formatMessage } = useIntl();

  const currentStatus = () => {
    if (categoriesLoading) {
      return LoadingBehavior();
    }
    if (categoriesError) {
      return ErrorBehavior(
        formatMessage({
          id: "ProductSelection.CategoryStatus.Error.DESCRIPTION",
        }),
        formatMessage({
          id: "ProductSelection.CategoryStatus.Error.BUTTON",
        }),
        retryCategories,
      );
    }
    return <></>;
  };

  return (
    <AnimatePresence key="category-loading">{currentStatus()}</AnimatePresence>
  );
}

function LoadingBehavior() {
  return (
    <motion.div
      key="categoty-loading"
      layout
      exit={{ height: 0, opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Styled.CategoryLoadContainer data-testid="category-loading">
        <Styled.CategoryLoad />
        <Styled.CategoryLoad />
        <Styled.CategoryLoad />
        <Styled.CategoryLoad />
        <Styled.CategoryLoad />
        <Styled.CategoryLoad />
      </Styled.CategoryLoadContainer>
    </motion.div>
  );
}

function ErrorBehavior(errorMessage, buttonLabel, retryCategories) {
  return (
    <Styled.CategoryErrorContainer data-testid="category-error">
      <div>{errorMessage}</div>
      <Button variant="secondary" onClick={retryCategories}>
        {buttonLabel}
      </Button>
    </Styled.CategoryErrorContainer>
  );
}
