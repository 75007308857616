import MessageMap from "../i18n.d";

const enUS: MessageMap = {
  TasksCarousel: {
    TITLE: "Call tasks",
    Empty: {
      DESCRIPTION: "There is no tasks assigned to this POC.",
    },
    Error: {
      DESCRIPTION: "Unable to load call tasks.",
      BUTTON: "Try again",
    },
    TaskCard: {
      TOOLTIP_SPONSORED: "Sponsored",
      Global: {
        Title: {
          TOPLINE: "Topline task",
          MARKETPLACE: "Marketplace task",
        },
        Description: {
          TOPLINE: "Recommend products based on the suggested order.",
          MARKETPLACE: "Encourage the purchase of marketplace products.",
        },
        Script: {
          TOPLINE:
            "Understand the gap between the current order and the suggested order and recommend products to achieve revenue goals.",
          MARKETPLACE:
            "Inform about the new marketplace products available and recommend them to the POC.",
        },
      },
      Local: {
        Description: {
          DEFAULT: "Customized task created by the operations teams.",
        },
      },
    },
    TaskDrawer: {
      MARK_AS_DONE: "Mark as done",
      MARK_AS_NOT_DONE: "Mark as not done",
      Error: {
        RELOAD: "Reload",
        GENERIC_ERROR:
          "Unable to load information. Check your connection and try again.",
      },
    },
  },
  ProductSelection: {
    TITLE: "Products",
    Empty: {
      EMPTY_PRODUCTS_MESSAGE:
        "This POC has no products in {param}. Try another category.",
      EMPTY_PRODUCTS_SEARCH_MESSAGE:
        'No results for "{searchInput}" in the catalog. Try another term.',
    },
    Error: {
      DESCRIPTION: "Unable to load products. Try reloading the page.",
      BUTTON: "Reload",
    },
    Pagination: {
      LOADING: "Loading",
      NO_MORE_ITEMS: "No more items to display.",
      Error: {
        TRY_AGAIN: "Try again",
        ERROR_TEXT: "Unable to load products.",
      },
    },
    Modes: {
      SUGGESTED: "Suggested order",
      PROMOTIONS: "Promotions",
      COMBOS: "Combos",
      RECOMMENDED: "Recommended",
    },
    CategoryStatus: {
      Error: {
        DESCRIPTION: "Unable to load categories.",
        BUTTON: "Try again",
      },
    },
    Search: {
      PLACEHOLDER: "Search by SKU or product",
      Filter: {
        DESCRIPTION: "Filter by:",
        PROMOTIONS: "Promotions",
      },
      Details: {
        SUMMARY_TEXT: "Showing {quantityProducts} results for ",
      },
    },
    SteppedDiscountDrawer: {
      TITLE: "Progressive discount",
      SUB_TITLE:
        "This product has progressive discount. More products give more discount according to the table below.",
      DISCOUNT_MESSAGE: "Add {quantity} get {discount}% off",
      DISCOUNT: "Discount",
      PRICE: "Price",
    },
  },
};

export default enUS;
