import { tokens } from "@bees-grow-shared/theme";
import { Button as HexaButton } from "@hexa-ui/components";
import { Check as HexaCheck } from "@hexa-ui/icons";
import styled, { css } from "styled-components";

const WIDTH_HAIRLINE = tokens.measure["border-width"].hairline;
const COLOR_INFO_DEFAULT = tokens.color.foundation.border.info.default;
const COLOR_SECONDARY = tokens.color.foundation.border.secondary;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${tokens.measure.space["spacing-2"]};
`;

export const Check = styled(HexaCheck)`
  fill: ${COLOR_INFO_DEFAULT};
`;

interface ButtonProps {
  checked: boolean;
}

export const Button = styled(HexaButton)<ButtonProps>`
  ${({ checked }) =>
    checked
      ? css`
          border: ${WIDTH_HAIRLINE} solid ${COLOR_INFO_DEFAULT};
          color: ${COLOR_INFO_DEFAULT};
          padding-right: 0.75rem;

          > div {
            width: ${tokens.measure.space["spacing-4"]};
            height: ${tokens.measure.space["spacing-4"]};
            margin: 0 ${tokens.measure.space["spacing-1"]};
            background: none;
          }
        `
      : css`
          border: ${WIDTH_HAIRLINE} solid ${COLOR_SECONDARY};
          padding: ${tokens.measure.space["spacing-2"]} 0.75rem;
        `}
`;
