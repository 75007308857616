import { MissionStatus } from "@bees-grow-shared/services";

import * as Styled from "./DrawerHeader.styles";

interface DrawerHeaderProps {
  status: MissionStatus;
  title: string;
}

export default function DrawerHeader({ status, title }: DrawerHeaderProps) {
  if (status?.isLoading) {
    return <LoadingBehavior />;
  }

  return (
    <Styled.Container data-testid="drawer-header">{title}</Styled.Container>
  );
}

function LoadingBehavior() {
  return (
    <Styled.ContainerLoading data-testid="drawer-header-loading">
      <Styled.LineLoading />
    </Styled.ContainerLoading>
  );
}
