import { useIntl } from "react-intl";

import CheckoutButton from "./components/checkoutButton/CheckoutButton";
import ShowLastOrderButton from "./components/showLastOrderButton/ShowLastOrderButton";
import { useProductSelectionHeader } from "./hooks/useProductSelectionHeader";
import * as Styled from "./ProductSelectionHeader.styles";

export default function ProductSelectionHeader() {
  const { formatMessage } = useIntl();
  const { isLoading } = useProductSelectionHeader();

  if (isLoading) {
    return <LoadingBehavior />;
  }

  return (
    <Styled.Container data-testid="product-selection-header">
      <Styled.Title size="H3" data-testid="product-selection-header-title">
        {formatMessage({
          id: "ProductSelection.TITLE",
        })}
      </Styled.Title>
      <Styled.ContainerButtons>
        <ShowLastOrderButton />
        <CheckoutButton />
      </Styled.ContainerButtons>
    </Styled.Container>
  );
}

function LoadingBehavior() {
  return <Styled.TitleLoad data-testid="product-selection-header-loading" />;
}
