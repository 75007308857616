import { Mission } from "@bees-grow-shared/services";

import { GetMissionDetailsResponse } from "@/services/mission/getMissionDetails/model/GetMissionDetailsResponse";

export function getMissionDetailsConverter(
  response: GetMissionDetailsResponse,
): Omit<Mission, "status" | "effectiveness"> {
  const {
    missionId,
    description,
    type,
    subType,
    script,
    missionName,
    products,
    combos,
  } = response;

  return {
    id: missionId,
    name: missionName,
    description,
    type,
    subType,
    script,
    products,
    combos,
  };
}
