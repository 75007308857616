import { useIntl } from "react-intl";

import { DrawerGeneric } from "@bees-grow-shared/components";

import DrawerHeader from "@/components/tasksCarousel/components/taskCard/components/drawerHeader/DrawerHeader";

import DiscountTable from "./components/discountTable/DiscountTable";
import { useSteppedDiscountDrawer } from "./hooks/useSteppedDiscountDrawer";
import * as Styled from "./SteppedDiscountDrawer.styles";

export default function SteppedDiscountDrawer() {
  const { showDrawer, handleShowSteppedDiscountDrawer, createTableRows } =
    useSteppedDiscountDrawer();
  const { formatMessage } = useIntl();

  return (
    <div data-testid="stepped-discount-drawer">
      <DrawerGeneric
        header={
          <DrawerHeader
            status={{ done: true, isLoading: false, error: false }}
            title={formatMessage({
              id: "ProductSelection.SteppedDiscountDrawer.TITLE",
            })}
          />
        }
        body={
          <>
            <Styled.Subtitle>
              {formatMessage({
                id: "ProductSelection.SteppedDiscountDrawer.SUB_TITLE",
              })}
            </Styled.Subtitle>
            <DiscountTable rows={createTableRows()} />
          </>
        }
        open={showDrawer}
        setOpen={handleShowSteppedDiscountDrawer}
        showCloseButton={true}
      >
        <></>
      </DrawerGeneric>
    </div>
  );
}
