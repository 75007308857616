import { useIntl } from "react-intl";

import { Table } from "@hexa-ui/components";

import * as Styled from "./DiscountTable.styles";

export default function DiscountTable({ rows }) {
  const { formatMessage } = useIntl();

  const columns = [
    {
      Header: formatMessage({
        id: "ProductSelection.SteppedDiscountDrawer.DISCOUNT",
      }),
      accessor: "discount",
      disableSortBy: true,
    },
    {
      Header: formatMessage({
        id: "ProductSelection.SteppedDiscountDrawer.PRICE",
      }),
      accessor: "price",
      disableSortBy: true,
    },
  ];

  return (
    <Styled.Container data-testid="discount-table">
      <Table pagination={false} columns={columns} data={rows} />
    </Styled.Container>
  );
}
