import { useMemo } from "react";

import {
  productsSharedService,
  useSharedUnit,
} from "@bees-grow-shared/services";

export function useProductsGrid() {
  const productService = productsSharedService();
  const {
    searchInput,
    products,

    initialSearchStatus: {
      isLoading: isInitialSearchStatusLoading,
      error: isInitialSearchStatusError,
      done: isInitialSearchStatusDone,
    },
  } = useSharedUnit(productService);

  const hasEmptyProducts = useMemo(
    () =>
      products.length === 0 &&
      isInitialSearchStatusDone &&
      !isInitialSearchStatusError,
    [products, isInitialSearchStatusDone, isInitialSearchStatusError],
  );

  return {
    isInitialSearchStatusLoading,
    isInitialSearchStatusError,
    hasEmptyProducts,
    products,
    searchInput,
  };
}
