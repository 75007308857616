import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import {
  GlobalId,
  missionSharedService,
  MissionType,
  useTaskDescription,
  useTaskTitle,
} from "@bees-grow-shared/services";

import { getMissionDetailsUseCase } from "@/useCases/mission/getMissionDetails/GetMissionDetailsUseCase";

interface UseTaskCardParams {
  id: string | GlobalId;
  title: string;
  description: string;
  type: MissionType;
}

interface UseTaskCardProps {
  titleFormatted: string;
  descriptionFormatted: string;
  drawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
  getMissionDetails: () => void;
}

export function useTaskCard({
  id,
  title,
  description,
  type,
}: UseTaskCardParams): UseTaskCardProps {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { formatMessage } = useIntl();
  const missionService = missionSharedService();

  const getFormattedMessage = (id: string) => formatMessage({ id });

  const descriptionLocalTranslate = getFormattedMessage(
    "TasksCarousel.TaskCard.Local.Description.DEFAULT",
  );

  const getMissionDetails = useCallback(() => {
    missionService.setMissionDetails({
      action: getMissionDetailsUseCase({ missionKey: id }),
      missionId: id,
    });
  }, [id, missionService]);

  useEffect(() => {
    if (drawerOpen) {
      getMissionDetails();
    }
  }, [drawerOpen, getMissionDetails]);

  const descriptionGlobalTranslate = {
    [GlobalId.MARKETPLACE]: getFormattedMessage(
      "TasksCarousel.TaskCard.Global.Description.MARKETPLACE",
    ),
    [GlobalId.TOPLINE]: getFormattedMessage(
      "TasksCarousel.TaskCard.Global.Description.TOPLINE",
    ),
  };

  const titleGlobalTranslate = {
    [GlobalId.MARKETPLACE]: getFormattedMessage(
      "TasksCarousel.TaskCard.Global.Title.MARKETPLACE",
    ),
    [GlobalId.TOPLINE]: getFormattedMessage(
      "TasksCarousel.TaskCard.Global.Title.TOPLINE",
    ),
  };

  const { description: descriptionFormatted } = useTaskDescription({
    id,
    description,
    type,
    defaultLocalTranslate: descriptionLocalTranslate,
    defaultGlobalTranslateMap: descriptionGlobalTranslate,
  });

  const { title: titleFormatted } = useTaskTitle({
    id,
    title,
    type,
    defaultGlobalTranslateMap: titleGlobalTranslate,
  });

  return {
    titleFormatted,
    descriptionFormatted,
    drawerOpen,
    setDrawerOpen,
    getMissionDetails,
  };
}
