import { tokens } from "@bees-grow-shared/theme";
import { EmptyState, Paragraph } from "@hexa-ui/components";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
`;

export const EmptyBehaviorContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HexaEmptyState = styled(EmptyState.SectionLevel)`
  p {
    line-height: ${tokens.measure["line-height"]["lineheight-5"]};
    font-size: ${tokens.measure["font-size"]["fontsize-2"]};
    color: ${tokens.color.foundation.txt.default};
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${tokens.measure.space["spacing-4"]};
  padding: ${tokens.measure.space["spacing-8"]} 0;
  width: 100%;
  height: 100%;
`;

export const ErrorParagraph = styled(Paragraph)`
  font-size: ${tokens.measure["font-size"]["fontsize-2"]};
  line-height: ${tokens.measure["line-height"]["lineheight-5"]};
  width: 11.25rem;
  height: fit-content;
  text-align: center;
`;
