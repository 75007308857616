import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

export const Bold = styled.span`
  font-weight: 600;
`;
