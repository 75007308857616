import { Mission } from "@bees-grow-shared/services";

import { GetMissionDetailsResponse } from "@/services/mission/getMissionDetails/model/GetMissionDetailsResponse";

export function getMissionDetailsConverter(
  response: GetMissionDetailsResponse,
): Omit<Mission, "status"> {
  const { missionId, description, type, script, missionName } = response;
  return {
    id: missionId,
    name: missionName,
    description,
    type,
    script,
  };
}
