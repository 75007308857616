import { useIntl } from "react-intl";

import { Paragraph } from "@hexa-ui/components";

import { useSearchFilter } from "./hooks/useSearchFilter";
import * as Styled from "./SearchFilter.styles";

export default function SearchFilter() {
  const { formatMessage } = useIntl();
  const { onlyPromotions, handleClick } = useSearchFilter();

  return (
    <Styled.Container data-testid="search-filter">
      <Paragraph data-testid="search-filter-description">
        {formatMessage({ id: "ProductSelection.Search.Filter.DESCRIPTION" })}
      </Paragraph>
      <Styled.Button
        data-testid="search-filter-button-promotions"
        icon={onlyPromotions ? MediumCheck : undefined}
        leading={onlyPromotions}
        checked={onlyPromotions}
        onClick={handleClick}
        size="small"
        variant="secondary"
      >
        {formatMessage({ id: "ProductSelection.Search.Filter.PROMOTIONS" })}
      </Styled.Button>
    </Styled.Container>
  );
}

const MediumCheck = () => (
  <div data-testid="medium-check-icon">
    <Styled.Check size="medium" />
  </div>
);
