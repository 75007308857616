import { useCallback, useEffect, useMemo } from "react";

import {
  accountSharedService,
  Mission,
  missionSharedService,
  useDefaultAnalyticsData,
  useSharedUnit,
  useWindowSize,
} from "@bees-grow-shared/services";

import analytics from "../../../config/typewriter";
import TasksCarousel from "../TasksCarousel";

const MEDIUM_CARDS_THRESHOLD = 1240;
const SMALL_CARDS_THRESHOLD = 1728;

export enum Size {
  xl = "xl",
  lg = "lg",
  md = "md",
  sm = "sm",
  xs = "xs",
}

interface UseTasksCarouselProps {
  tasks: Mission[];
  isLoading: boolean;
  isEmpty: boolean;
  hasError: boolean;
  size: Size;
  cardsPerPage: number;
  retry: () => void;
}

export function useTasksCarousel(): UseTasksCarouselProps {
  const { width } = useWindowSize();
  const accountService = accountSharedService();
  const missionService = missionSharedService();
  const {
    accountId,
    vendorId,
    error: accountError,
  } = useSharedUnit(accountService);
  const {
    missions: tasks,
    prioritizedMissions: {
      isLoading,
      error: prioritizedMissionsError,
      done: prioritizedMissionsDone,
    },
  } = useSharedUnit(missionService);
  const defaultData = useDefaultAnalyticsData();

  const getPrioritizedMissions = useCallback(() => {
    missionService.setPrioritizedMissions({ accountId, vendorId });
  }, [accountId, missionService, vendorId]);

  useEffect(() => {
    if (!prioritizedMissionsDone && !prioritizedMissionsError) {
      getPrioritizedMissions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPrioritizedMissions, prioritizedMissionsDone]);

  useEffect(() => {
    if (prioritizedMissionsDone) {
      analytics.callTasksLoaded({
        tasks_quantity: tasks?.length,
        tasks: tasks?.map(({ name }, index) => ({ name, priority: index + 1 })),
        ...defaultData,
        screen_name: "MISSIONS",
        component_name: TasksCarousel.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prioritizedMissionsDone]);

  const hasError = useMemo(
    () => accountError || prioritizedMissionsError,
    [accountError, prioritizedMissionsError],
  );

  const isEmpty = tasks?.length === 0;

  const size = useMemo(() => {
    if (width <= MEDIUM_CARDS_THRESHOLD) {
      return Size.md;
    }
    if (MEDIUM_CARDS_THRESHOLD < width && width <= SMALL_CARDS_THRESHOLD) {
      return Size.sm;
    }
    return Size.xs;
  }, [width]);

  const cardsPerPage = useMemo(() => {
    if (size === Size.md) {
      return 3;
    }
    if (size === Size.sm) {
      return 4;
    }
    return 5;
  }, [size]);

  const retry = useCallback(() => {
    missionService.setPrioritizedMissions({ accountId, vendorId });
  }, [missionService, accountId, vendorId]);

  const tasksByEffectiveness = useMemo(() => {
    const completeTasks = tasks?.filter((task) => task.effectiveness) ?? [];
    const incompleteTasks = tasks?.filter((task) => !task.effectiveness) ?? [];
    return [...incompleteTasks, ...completeTasks];
  }, [tasks]);

  return {
    tasks: tasksByEffectiveness,
    isLoading,
    isEmpty,
    hasError,
    size,
    cardsPerPage,
    retry,
  };
}
