import { useEffect } from "react";

import { productsSharedService } from "@bees-grow-shared/services";
import { debounce } from "lodash";

export interface ScrollRestorationProps {
  scrollPosition: number;
}

export function useScrollRestoration({
  scrollPosition,
}: ScrollRestorationProps) {
  const productService = productsSharedService();

  useEffect(() => {
    const debouncedSetProductsState = debounce(() => {
      productService.setProductsState({ scrollPosition: window.scrollY });
    }, 600);

    const handleScroll = () => {
      debouncedSetProductsState();
    };

    window.addEventListener("scroll", handleScroll);

    if (scrollPosition > 0) {
      window.scrollTo(0, scrollPosition);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
      debouncedSetProductsState.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
