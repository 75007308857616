import { useCallback } from "react";

import {
  productsSharedService,
  useSharedUnit,
} from "@bees-grow-shared/services";

export function useSearchFilter() {
  const productsService = productsSharedService();
  const { onlyPromotions } = useSharedUnit(productsService);

  const handleClick = useCallback(() => {
    productsService.changeFilters({
      onlyPromotions: !onlyPromotions,
    });
  }, [onlyPromotions, productsService]);

  return { onlyPromotions, handleClick };
}
