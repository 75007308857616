import { useCallback } from "react";
import { useIntl } from "react-intl";

import {
  productsSharedService,
  useSharedUnit,
} from "@bees-grow-shared/services";

import { useScrollRestoration } from "../useScrollRestoration/useScrollRestoration";

export function usePaginationSection() {
  const productsService = productsSharedService();

  const { formatMessage } = useIntl();

  const {
    hasNextPage,
    scrollPosition,
    pageSearchStatus: {
      error: isPageSearchStatusError,
      isLoading: isPageSearchStatusLoading,
      done: isPageSearchStatusDone,
    },
  } = useSharedUnit(productsService);

  useScrollRestoration({ scrollPosition });

  const callGetNextPage = useCallback(() => {
    productsService.getNextPageProducts();
  }, [productsService]);

  const retryButtonText = formatMessage({
    id: "ProductSelection.Pagination.Error.TRY_AGAIN",
  });

  const errorText = formatMessage({
    id: "ProductSelection.Pagination.Error.ERROR_TEXT",
  });

  const loadingText = formatMessage({
    id: "ProductSelection.Pagination.LOADING",
  });

  const emptyStateText = formatMessage({
    id: "ProductSelection.Pagination.NO_MORE_ITEMS",
  });

  return {
    hasNextPage,
    callGetNextPage,
    isPageSearchStatusDone,
    isPageSearchStatusLoading,
    isPageSearchStatusError,
    retryButtonText,
    errorText,
    emptyStateText,
    loadingText,
  };
}
