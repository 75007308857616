import { useIntl } from "react-intl";

import { RetryAlert } from "@bees-grow-shared/components";
import { Carousel, Paragraph } from "@hexa-ui/components";

import CarouselTitle from "./components/carouselTitle/CarouselTitle";
import TaskCard from "./components/taskCard/TaskCard";
import { useTasksCarousel } from "./hooks/useTasksCarousel";
import * as Styled from "./TasksCarousel.styles";

export default function TasksCarousel() {
  const { tasks, isLoading, isEmpty, hasError, size, cardsPerPage, retry } =
    useTasksCarousel();

  if (isLoading) {
    return <LoadingBehavior cardsPerPage={cardsPerPage} />;
  }

  if (hasError) {
    return <ErrorBehavior retry={retry} />;
  }

  if (isEmpty) {
    return <EmptyBehavior />;
  }

  return (
    <Styled.Container data-testid="tasks-carousel-container">
      <CarouselTitle tasksCount={tasks.length} />
      <Carousel.Root
        size={size}
        showArrows
        autoHideIndicators
        swipeable={false}
      >
        {tasks.map((task) => (
          <Carousel.Item key={task.id}>
            <TaskCard
              key={task.id}
              task={task}
              isDone={false}
              isSponsored={false}
            />
          </Carousel.Item>
        ))}
      </Carousel.Root>
    </Styled.Container>
  );
}

interface LoadingBehaviorProps {
  cardsPerPage: number;
}

function LoadingBehavior({ cardsPerPage }: LoadingBehaviorProps) {
  return (
    <Styled.ContainerLoading data-testid="tasks-carousel-loading">
      <Styled.TasksCarouselTitleContainerLoading>
        <Styled.TasksCarouselTitle />
      </Styled.TasksCarouselTitleContainerLoading>
      <Styled.TasksCarouselLoading>
        {Array.from({ length: cardsPerPage }).map((_, index) => (
          <Styled.TaskCardLoading key={index}>
            <Styled.CardTextContainerLoading>
              <Styled.CardTitleLoading />
            </Styled.CardTextContainerLoading>
            <Styled.CardTextContainerLoading>
              <Styled.CardDescriptionLoading />
            </Styled.CardTextContainerLoading>
          </Styled.TaskCardLoading>
        ))}
      </Styled.TasksCarouselLoading>
    </Styled.ContainerLoading>
  );
}

interface ErrorBehaviorProps {
  retry: () => void;
}

function ErrorBehavior({ retry }: ErrorBehaviorProps) {
  const { formatMessage } = useIntl();

  return (
    <Styled.ContainerError data-testid="tasks-carousel-error">
      <CarouselTitle />
      <RetryAlert
        message={formatMessage({ id: "TasksCarousel.Error.DESCRIPTION" })}
        actionLabel={formatMessage({ id: "TasksCarousel.Error.BUTTON" })}
        action={retry}
      />
    </Styled.ContainerError>
  );
}

function EmptyBehavior() {
  const { formatMessage } = useIntl();

  return (
    <Styled.ContainerEmpty data-testid="tasks-carousel-empty">
      <CarouselTitle />
      <Paragraph>
        {formatMessage({
          id: "TasksCarousel.Empty.DESCRIPTION",
        })}
      </Paragraph>
    </Styled.ContainerEmpty>
  );
}
