import { tokens } from "@bees-grow-shared/theme";
import styled from "styled-components";

interface GridItemProps {
  isLastInRow: boolean;
  isLastInColumn: boolean;
}

export const BORDER_PROPERTIES = `1px solid ${tokens.color.foundation.border.secondary};`;

export const getBorderRightStyle = (isLastInRow: boolean): string =>
  isLastInRow ? "none" : BORDER_PROPERTIES;

export const getBorderBottomStyle = (isLastInColumn: boolean): string =>
  isLastInColumn ? "none" : BORDER_PROPERTIES;

export const GridContainer = styled.div`
  display: grid;
  gap: 0;
  @media (max-width: 1023px) {
    grid-template-columns: repeat(2, minmax(min-content, 24.625rem));
  }

  @media (min-width: 1024px) and (max-width: 1439px) {
    grid-template-columns: repeat(3, minmax(min-content, 24.625rem));
  }

  @media (min-width: 1440px) and (max-width: 1919px) {
    grid-template-columns: repeat(4, minmax(min-content, 24.625rem));
  }
  @media (min-width: 1920px) {
    grid-template-columns: repeat(5, minmax(min-content, 24.625rem));
  }
`;

export const GridCard = styled.div<GridItemProps>`
  border-right: ${({ isLastInRow }) => getBorderRightStyle(isLastInRow)};
  border-bottom: ${({ isLastInColumn }) =>
    getBorderBottomStyle(isLastInColumn)};
  text-align: start;

  width: 100%;
  height: 24.625rem;
  box-sizing: border-box;
`;
